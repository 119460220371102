import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,

  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,

  UPLOAD_RBF_SUCCESS,

  VERIFI_NUMBER,
  VERIFI_NUMBER_SUCCESS,
  VERIFI_NUMBER_ERROR,

  VERIFI_OTP,
  VERIFI_OTP_SUCCESS,
  VERIFI_OTP_ERROR,

  UPLOAD_RBF_AVTAR,
  UPLOAD_RBF_AVTAR_SUCCESS,
  UPLOAD_RBF_AVTAR_ERROR,

  AVTAR_MODEL,
  AVTAR_MODEL_SUCCESS,
  AVTAR_MODEL_ERROR,

  UPLOAD_MESH_RBF_AVTAR,
  UPLOAD_MESH_RBF_AVTAR_SUCCESS,
  UPLOAD_MESH_RBF_AVTAR_ERROR,
  AVTAR_MESH_MODEL_SUCCESS,

  DOWNLOAD_MESH_AVTAR,

  MESH_MODEL,
  MESH_MODEL_SUCCESS,
  MESH_MODEL_ERROR,

  GET_PERMISSIONS,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_ERROR,

  GET_All_ACCESS,
  GET_All_ACCESS_SUCCESS,
  GET_All_ACCESS_ERROR,

  UPDATE_PERMISSIONS,
  UPDATE_PERMISSIONS_SUCCESS,
  UPDATE_PERMISSIONS_ERROR,

  UPDATE_POSITION,
  UPDATE_POSITION_SUCCESS,
  UPDATE_POSITION_ERROR,

  IRB_USER_DETAILS,
  IRB_USER_DETAILS_SUCCESS,
  IRB_USER_DETAILS_ERROR,

  CONFIRM_GUARDIANT_IRB,
  CONFIRM_GUARDIANT_IRB_SUCCESS,
  CONFIRM_GUARDIANT_IRB_ERROR,

  RESET_STATE,
  AVATAR_PROFILE_PICTURE_URL,

  DELETE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_FAIL,
  DELETE_USER_ACCOUNT_STATE,
  DELETE_USER_ACCOUNT_AFTER_SUCCESS
} from "./actionTypes"

const initialState = {
  loading: false,
  profile: null,
  error: null,
  irb_pdf: null,

  verifyNumber: false,
  verifySuccess: null,
  verifyErr: null,

  verifyOtp: false,
  verifyOtpSuccess: null,
  verifyOtpErr: null,

  editing: false,
  editSuccess: null,
  editErr: null,

  uploading: false,
  uploadingSuccess: null,
  uploadingErr: null,

  profileModelLoading: false,
  profileModel: null,
  profileModelErr: null,

  getPermissions: false,
  getPermissionsSucc: null,
  getPermissionsErr: null,
  getAllPermissions: false,
  getAllPermissionsSucc: null,
  getAllPermissionsErr: null,

  updatePermissions: false,
  updatePermissionsSucc: null,
  updatePermissionsErr: null,

  updatePosition: false,
  updatePositionSucc: null,
  updatePositionErr: null ,

  IrbUserdeatils: false,
  IrbUserdeatilsSucc: null,
  IrbUserdeatilsErr: null,

  confirmGuardiantIrb: false,
  confirmGuardiantIrbSucc: null,
  confirmGuardiantIrbErr: null,

  uploadingMesh: false,
  uploadingMeshSuccess: null,
  downloadMeshSuccess: null,

  uploadingMeshErr: null,
  customMeshUrl: null,
  meshModelLoading: false,
  meshModel: null,
  meshModelErr: null,
  // MeshModel: false,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    //Confirm guardiant ...
    case CONFIRM_GUARDIANT_IRB:
      state = {
        ...state,
        confirmGuardiantIrb: true,
        confirmGuardiantIrbSucc: null,
        confirmGuardiantIrbErr: null
      }
      break
    case CONFIRM_GUARDIANT_IRB_SUCCESS:
      state = {
        ...state,
        confirmGuardiantIrb: false,
        confirmGuardiantIrbSucc: action.payload,
        confirmGuardiantIrbErr: null
      }
      break
    case CONFIRM_GUARDIANT_IRB_ERROR:
      state = {
        ...state,
        confirmGuardiantIrb: false,
        confirmGuardiantIrbSucc: null,
        confirmGuardiantIrbErr: action.payload
      }
    //Irb users details...
    case IRB_USER_DETAILS:
      state = {
        ...state,
        IrbUserdeatils: true,
        IrbUserdeatilsSucc: null,
        IrbUserdeatilsErr: null
      }
      break
    case IRB_USER_DETAILS_SUCCESS:
      state = {
        ...state,
        IrbUserdeatils: false,
        IrbUserdeatilsSucc: action.payload,
        IrbUserdeatilsErr: null
      }
      break
    case IRB_USER_DETAILS_ERROR:
      state = {
        ...state,
        IrbUserdeatils: false,
        IrbUserdeatilsSucc: null,
        IrbUserdeatilsErr: action.payload
      }
    //updpate positions and sensor ...
    case UPDATE_POSITION:
      state = {
        ...state,
        updatePosition: true,
        updatePositionSucc: null,
        updatePositionErr: null
      }
      break
    case UPDATE_POSITION_SUCCESS:
      state = {
        ...state,
        updatePosition: false,
        updatePositionSucc: action.payload,
        updatePositionErr: null
      }
      break
    case UPDATE_POSITION_ERROR:
      state = {
        ...state,
        updatePosition: false,
        updatePositionSucc: null,
        updatePositionErr: action.payload
      }
    //updpate permissions ...
    case UPDATE_PERMISSIONS:
      state = {
        ...state,
        updatePermissions: true,
        updatePermissionsSucc: null,
        updatePermissionsErr: null
      }
      break
    case UPDATE_PERMISSIONS_SUCCESS:
      state = {
        ...state,
        updatePermissions: false,
        updatePermissionsSucc: action.payload,
        updatePermissionsErr: null
      }
      break
    case UPDATE_PERMISSIONS_ERROR:
      state = {
        ...state,
        updatePermissions: false,
        updatePermissionsSucc: null,
        updatePermissionsErr: action.payload
      }

    //profile model...
    case GET_PERMISSIONS:
      state = {
        ...state,
        getPermissions: true,
        getPermissionsSucc: null,
        getPermissionsErr: null
      }
      break
    case GET_PERMISSIONS_SUCCESS:
      state = {
        ...state,
        getPermissions: false,
        getPermissionsSucc: action.payload,
        getPermissionsErr: null
      }
      break
    case GET_PERMISSIONS_ERROR:
      state = {
        ...state,
        getPermissions: false,
        getPermissionsSucc: null,
        getPermissionsErr: action.payload
      }
      break
    case GET_All_ACCESS:
      state = {
        ...state,
        getAllPermissions: true,
        getAllPermissionsSucc: null,
        getAllPermissionsErr: null,
        seperateListing: true

      }
      break
    case GET_All_ACCESS_SUCCESS:
      state = {
        ...state,
        getAllPermissions: false,
        getAllPermissionsSucc: action.payload,
        getAllPermissionsErr: null,
        seperateListing: true
      }
      break
    case GET_All_ACCESS_ERROR:
      state = {
        ...state,
        getAllPermissions: false,
        getAllPermissionsSucc: null,
        getAllPermissionsErr: action.payload,
        seperateListing: true
      }
      break
    // mesh model...
    case UPLOAD_MESH_RBF_AVTAR:
      state = {
        ...state,
        uploadingMesh: false,
        downloadMeshSuccess: null,
        uploadingMeshSuccess: null,
        uploadingMeshErr: null
      }
      break
    case UPLOAD_MESH_RBF_AVTAR_SUCCESS:
      state = {
        ...state,
        uploadingMesh: true,
        downloadMeshSuccess: null,
        uploadingMeshSuccess: action.payload,
        uploadingMeshErr: null,
        profile: Object.assign({}, state.profile, {
          mesh_status: "Please be patient. Subject-specific head model still processing..."
        })
      }
      break
    case UPLOAD_MESH_RBF_AVTAR_ERROR:
      state = {
        ...state,
        uploadingMesh: false,
        downloadMeshSuccess: null,
        uploadingMeshSuccess: null,
        uploadingMeshErr: action.payload
      }
      break
    case AVTAR_MESH_MODEL_SUCCESS:
      state = {
        ...state,
        uploadingMesh: false,
        uploadingMeshSuccess: action.payload,
        profile: Object.assign({}, state.profile, {
          mesh_status: "complete"
        })
      }
      break
    case MESH_MODEL:
      state = {
        ...state,
        meshModelLoading: true,
        meshModelErr: null
      }
      break
    case MESH_MODEL_SUCCESS:
      state = {
        ...state,
        meshModelLoading: false,
        meshModel: action.payload,
        meshModelErr: null
      }
      break
    case MESH_MODEL_ERROR:
      state = {
        ...state,
        uploading: false,
        meshModelLoading: false,
        meshModel: null,
        meshModelErr: action.payload
      }
      break
    //Download model..
    case DOWNLOAD_MESH_AVTAR:
      state = {
        ...state,
        uploadingMesh: false,
        uploadingMeshSuccess: null,
        downloadMeshSuccess: action.payload,
        uploadingMeshErr: null,
        profile: Object.assign({}, state.profile, {
          mesh_status: ""
        })
      }
      break
    //profile model...
    case AVTAR_MODEL:
      state = {
        ...state,
        profileModelLoading: true,
        uploadingSuccess: null,
        profileModelErr: null
      }
      break
    case AVTAR_MODEL_SUCCESS:
      state = {
        ...state,
        profileModelLoading: false,
        profileModel: action.payload,
        profileModelErr: null
      }
      break
    case AVTAR_MODEL_ERROR:
      state = {
        ...state,
        uploading: false,
        profileModelLoading: false,
        profileModel: null,
        profileModelErr: action.payload
      }

    //uploading profile pic...
    case UPLOAD_RBF_AVTAR:
      state = {
        ...state,
        uploading: true,
        uploadingSuccess: null,
        uploadingErr: null
      }
      break
    case UPLOAD_RBF_AVTAR_SUCCESS:
      state = {
        ...state,
        uploading: false,
        uploadingSuccess: action.payload,
        uploadingErr: null,
        profile: Object.assign({}, state.profile, {
          selfie_image_uploaded: 1,
          rbf_status: "Please be patient. Subject-specific head model still processing... Or You can come back after 5 minutes."
        })
      }
      break
    case UPLOAD_RBF_AVTAR_ERROR:
      state = {
        ...state,
        uploading: false,
        uploadingSuccess: null,
        uploadingErr: action.payload,
        profile: Object.assign({}, state.profile, {
          selfie_image_uploaded: 1,
          rbf_status: "avatar_generation_failed",
          //loading: false
        }),
      }
    //get profile...
    case GET_PROFILE:
      state = {
        ...state,
        loading: true,
        profile: null,
        error: null,
        irb_pdf: null,

      }
      break
    case GET_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        profile: action.payload.user,
        irb_pdf: action.payload.irb_pdf,
        error: null
      }
      break
    case UPLOAD_RBF_SUCCESS:
      state = {
        ...state,
        loading: false,
        profile: Object.assign({}, state.profile, {
          selfie_image_uploaded: 1,
          rbf_status: null
        }),
        error: null
      }
      break
    case GET_PROFILE_ERROR:
      state = {
        ...state,
        loading: false,
        profile: null,
        irb_pdf: null,
        error: action.payload
      }
    //Verify number...
    case VERIFI_NUMBER:
      state = {
        ...state,
        verifyNumber: true,
        verifySuccess: null,
        verifyErr: null
      }
      break
    case VERIFI_NUMBER_SUCCESS:
      state = {
        ...state,
        verifyNumber: false,
        verifySuccess: action.payload,
        verifyErr: null
      }
      break
    case VERIFI_NUMBER_ERROR:
      state = {
        ...state,
        verifyNumber: false,
        verifySuccess: null,
        verifyErr: action.payload
      }
    //Verify otp...
    case VERIFI_OTP:
      state = {
        ...state,
        verifyOtp: true,
        verifyOtpSuccess: null,
        verifyOtpErr: null
      }
      break
    case VERIFI_OTP_SUCCESS:
      state = {
        ...state,
        verifyOtp: false,
        verifyOtpSuccess: action.payload,
        verifyOtpErr: null
      }
      break
    case VERIFI_OTP_ERROR:
      state = {
        ...state,
        verifyOtp: false,
        verifyOtpSuccess: null,
        verifyOtpErr: action.payload
      }
      break
    //Edit User profile...
    case EDIT_PROFILE:
      state = {
        ...state,
        editing: true,
        editSuccess: null,
        editErr: null
      }
      break
    case PROFILE_SUCCESS:
      state = {
        ...state,
        editing: false,
        editSuccess: action.payload,
        editErr: null
      }
      break
    case PROFILE_ERROR:
      state = {
        ...state,
        editing: false,
        editSuccess: null,
        editErr: action.payload
      }
      break
    case AVATAR_PROFILE_PICTURE_URL:
      state = {
        ...state,
        profileModel: action.payload
      }
      break
    //reset state.
    case RESET_STATE:
      state = {
        ...state,
        verifyNumber: false,
        verifySuccess: null,
        verifyErr: null,

        verifyOtp: false,
        verifyOtpSuccess: null,
        verifyOtpErr: null,

        editing: false,
        editSuccess: null,
        editErr: null
      }
      break
    // delete account...
    case DELETE_USER_ACCOUNT:
      return {
        ...state,
        userAccountDeleteStart: true,
        userAccountDeleteSuccess: null,
        userAccountDeleteErr: null,
        userAccountBeforeSuccess: null,
      }
    case DELETE_USER_ACCOUNT_STATE:
      return {
        ...state,
        userAccountBeforeSuccess: action.payload
      }
    case DELETE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        userAccountDeleteStart: false,
        userAccountDeleteSuccess: action.payload,
        userAccountDeleteErr: null,
        userAccountBeforeSuccess: null,
      }
    case DELETE_USER_ACCOUNT_FAIL:
      return {
        ...state,
        userAccountDeleteStart: false,
        userAccountDeleteSuccess: null,
        userAccountDeleteErr: action.payload,
        userAccountBeforeSuccess: null,
      }
    case DELETE_USER_ACCOUNT_AFTER_SUCCESS:
      return {
        ...state,
        userAccountDeleteStart: false,
        userAccountDeleteSuccess: null,
        userAccountDeleteErr: null,
        userAccountBeforeSuccess: null,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
