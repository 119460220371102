import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isLogoutUser,
  isAuthProtected,
  privateRoute,
  userAccessLevel,
  path,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const { pathname } = props.location
      if (!pathname.includes("privacy") && !pathname.includes("user/verification") && !pathname.includes("simulation/event-details")) {
        const sitePassword = localStorage.getItem('sitePassword');
        const sitePasswordTime = parseInt(localStorage.getItem('sitePasswordTime'), 10);
        // console.log('rendered', {
        //   sitePassword,
        //   sitePasswordTime
        // })
        if (!sitePassword || !sitePasswordTime) {
          if (path !== '/site-password') {
            return (
              <Redirect to={{pathname: '/site-password', state: { from: props.location }}}/>
            )
          }
        }

        if (sitePassword && sitePasswordTime) {
          const currentTime = new Date()
          const currentTimeStamp = currentTime.getTime()
          const difference = currentTimeStamp - sitePasswordTime
          const hours = difference / (60 * 60 * 1000)
          if (hours > 24) {
            localStorage.removeItem('sitePassword')
            localStorage.removeItem('sitePasswordTime')
            if (path !== '/site-password') {
              return (
                <Redirect to={{pathname: '/site-password', state: { from: props.location }}}/>
              )
            }
          }
        }
      }
      // Signout user if token invalid ...
      if (isLogoutUser) {
        localStorage.removeItem("authUser");
        return (
          <Redirect
            to={{ pathname: "/home", state: { from: props.location } }}
          />
        )
      }

      //redirect user if user not logged in
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        if (path == "/player/details/:event_id") {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        } else {

          return (
            <Redirect
              to={{ pathname: "/home", state: { from: props.location } }}
            />
          )
        }
      }

      /**
       * Validated user route accroding level
       */
      let user = localStorage.getItem("authUser");
      if (user) user = JSON.parse(user);
      if (privateRoute && !user) {
        return (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        )
      }

      if (privateRoute && user.level < userAccessLevel  ) {
        return (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        )
      }

      // @end....

      // return layout...
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default Authmiddleware
